.aboutTour {
  padding: 0 100px;
  overflow-y: scroll;
  margin-bottom: 40px;
}

.aboutTour::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.aboutTour .tourCard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.aboutTour .tourCard:nth-child(even) {
  flex-direction: row-reverse;
}

.aboutTour .tourCard .left {
  width: 38%;
}

.aboutTour .tourCard .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutTour .tourCard .right {
  padding: 15px 50px;
  width: 60%;
}

.aboutTour .tourCard .right h3 {
  font-size: 32px;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  margin: 30px 0;
}

@media (max-width: 1024px) {
  .aboutTour {
    padding: 50px;
  }

  .aboutTour .tourCard .left {
    width: 45%;
  }

  .aboutTour .tourCard .right {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .aboutTour {
    margin-bottom: 0;
  }

  .aboutTour .tourCard {
    flex-direction: column;
    border-top: none;
  }

  .aboutTour .tourCard:nth-child(even) {
    flex-direction: column;
  }

  .aboutTour .tourCard .left,
  .aboutTour .tourCard .right {
    width: 100%;
  }

  .aboutTour .tourCard .left {
    padding: 0 50px;
  }
}

@media (max-width: 680px) {
  .aboutTour {
    padding: 40px;
  }

  .aboutTour .tourCard .left {
    padding: 0;
  }

  .aboutTour .tourCard .right {
    padding: 0 0 50px;
  }

  .aboutTour .tourCard .right p {
    text-align: justify;
  }
}

@media (max-width: 320px) {
  .aboutTour {
    padding: 25px;
  }
}
