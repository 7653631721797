.navbar {
  width: 100%;
  padding: 0 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: background-color 0.3s ease-in-out;
}

.navbar.white {
  background-color: #fff;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s ease-in-out;
}

.navbar .logo {
  height: 80%;
}

.navbar .logo img {
  height: 100%;
  object-fit: contain;
}

.navbar.white .logo img {
  filter: invert(95%);
}

.navbar .nav {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar .nav a,
.navbar .nav p.contactButton {
  margin-right: 40px;
  font-weight: 500;
  color: #fff;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar.white .nav a,
.navbar.white .nav p.contactButton {
  color: #333;
}

.navbar button.makePayment {
  padding: 10px 20px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0ac0e;
  background-image: linear-gradient(90deg, #f0ac0e, #f56616);
  outline: none;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: inset 3px 5px 15px rgba(255, 255, 255, 0.3);
}

.navbar button.makePayment p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  transform: translateX(7px);
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment:hover p {
  transform: translateX(-10px);
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment i {
  color: #fff;
  font-size: 16px;
  transform: translateX(80px);
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment:hover i {
  transform: translateX(8px);
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment:active {
  box-shadow: inset 3px 5px 15px rgba(0, 0, 0, 0.3),
    inset -5px -5px 15px rgba(255, 255, 255, 0.3);
}

.navbar .nav a::after,
.navbar .nav p.contactButton::after {
  content: "";
  position: absolute;
  bottom: 14px;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: #fff;
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navbar.white .nav a::after,
.navbar.white .nav p.contactButton::after {
  background-color: #333;
}

.navbar .nav a:hover::after,
.navbar .nav p.contactButton:hover::after {
  transform: scaleX(1);
  transition: transform 0.3s ease;
}

.modal {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 30px 40px;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 10px;
}

.modal h3 {
  color: #000;
  font-weight: 500;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.modal .inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.modal .inputs .input {
  margin-bottom: 15px;
}

.navbar .hamburgerLines {
  width: 20px;
  height: 15px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.navbar .hamburgerLines .line {
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.navbar .hamburger {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  display: none;
  flex-direction: column;
  padding: 100px;
  backdrop-filter: blur(10px);
}

.navbar .hamburger a,
.navbar .hamburger p {
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 40px;
}

.navbar .hamburger a i,
.navbar .hamburger p i {
  margin-right: 15px;
}

.navbar .hamburger i.fa-times {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
  color: #fff;
}

.navbar .hamburger.active {
  display: flex;
}

@media (max-width: 768px) {
  .navbar {
    background-color: #fff;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid #999;
  }

  .navbar img {
    filter: invert(95%);
  }

  .navbar .nav {
    display: none;
  }

  .navbar .hamburgerLines {
    display: flex;
  }

  .navbar .hamburgerLines .line {
    background-color: #333;
  }

  .navbar .hamburger button.makePayment {
    width: 80%;
    padding: 30px;
  }

  .navbar .hamburger button.makePayment p {
    font-size: 22px;
  }
}

@media (max-width: 680px) {
  .navbar {
    padding: 0 20px;
    height: 50px;
  }
  .navbar img {
    height: 70%;
  }
  .navbar .hamburger {
    padding: 100px 50px;
  }
}
