.connect {
  padding: 40px;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url("../../../../../assets/road\ background.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect h2 {
  font-size: 45px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

.connect p {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
}

.connect button {
  padding: 20px 50px;
  border: 1px solid #fff;
  outline: none;
  background-color: transparent;
  font-size: 19px;
  color: #fff;
  font-weight: 500;
  margin: 30px 0;
  transition: background-color 0.3s;
  cursor: pointer;
  border-radius: 5px;
}

.connect button:hover {
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s;
}
@media (max-width: 680px) {
  .connect h2 {
    font-size: 35px;
    margin-bottom: 15px;
  }

  .connect p {
    font-size: 16px;
  }

  .connect button {
    padding: 15px 30px;
    font-size: 18px;
    margin: 20px 0 0;
  }
}

@media (max-width: 380px) {
  .connect {
    padding: 30px;
  }

  .connect h2 {
    font-size: 30px;
  }

  .connect p {
    font-size: 14px;
  }

  .connect button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 20px 0 0;
  }
}

@media (max-width: 320px) {
  .connect {
    padding: 20px;
  }
}
