.hero {
  width: 100%;
  /* height: fit-content; */
  overflow: hidden;
  position: relative;
}

.hero h3 {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 80px;
  z-index: 9;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #00000080;
  padding-left: 150px;
}

.hero h3 img {
  width: 120px;
  object-fit: contain;
}

.hero img {
  width: 100%;
  object-fit: contain;
}

.hero .slick-prev {
  left: 0;
  z-index: 2;
}

.hero .slick-next {
  right: 0;
}

.hero .slick-next,
.hero .slick-prev {
  /* top: 35%; */
  background-color: #00000060 !important;
  width: 40px;
  height: 50px;
  color: #fff;
  z-index: 99;
}

.hero .slick-next:hover,
.hero .slick-prev:hover {
  background-color: #00000060;
}

.hero .slick-next::before {
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  content: "\f054";
  font-weight: 900;
  color: #fff;
}

.hero .slick-prev::before {
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  content: "\f053";
  font-weight: 900;
  color: #fff;
}

@media (max-width: 768px) {
  .hero h3 {
    padding-left: 100px;
    font-size: 50px;
  }
}

@media (max-width: 680px) {
  .hero h3 {
    padding-left: 50px;
    font-size: 30px;
  }

  .hero .slick-next,
  .hero .slick-prev {
    width: 30px;
    height: 40px;
  }

  .hero .slick-prev::before,
  .hero .slick-next::before {
    font-size: 18px;
  }
}
