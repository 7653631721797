.locationCard {
  position: relative;
  width: 350px;
  height: 480px;
}

.locationCard img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  filter: brightness(75%);
}

.locationCard h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  color: #fff;
  text-align: center;
}

@media (max-width: 768px) {
  .locationCard {
    width: 270px;
    height: 400px;
  }
}
