.toursWeProvide {
  padding: 30px 100px 50px;
  background-color: #f8f8f8;
}

.toursWeProvide h2 {
  text-decoration: underline;
  font-size: 32px;
  margin-bottom: 40px;
  padding-left: 20px;
}

.toursWeProvide .cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toursWeProvide .cards .card {
  width: 30%;
  padding: 35px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.toursWeProvide .cards .card .top {
  width: 100%;
}

.toursWeProvide .cards .card .top img {
  width: 100%;
  height: 164px;
  object-fit: cover;
  object-position: bottom;
  /* background-position: bottom; */
  border-radius: 10px;
  /* border: 1px solid #000; */
}

.toursWeProvide .cards .card:first-child .top img {
  object-position: 0 -60px;
}

.toursWeProvide .cards .card .bottom h4 {
  font-size: 25px;
  text-align: center;
  margin: 10px 0;
}

.toursWeProvide .cards .card .bottom p {
  line-height: 30px;
  text-align: justify;
}

@media (max-width: 1024px) {
  .toursWeProvide {
    padding: 50px;
  }

  .toursWeProvide .cards .card {
    width: 32%;
  }

  .toursWeProvide .cards .card .bottom p {
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .toursWeProvide .cards {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }

  .toursWeProvide .cards .card {
    width: 320px;
    margin-bottom: 20px;
  }
}

@media (max-width: 680px) {
  .toursWeProvide {
    padding: 30px;
  }

  .toursWeProvide h2 {
    padding: 0;
  }

  .toursWeProvide .cards {
    justify-content: center;
  }

  .toursWeProvide .cards .card:first-child .top img {
    object-position: bottom;
  }
}

@media (max-width: 320px) {
  .toursWeProvide {
    padding: 20px;
  }
}
