@import url(https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.App {
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #8b8a88;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .App {
    padding-top: 50px;
  }
}

.navbar {
  width: 100%;
  padding: 0 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: background-color 0.3s ease-in-out;
}

.navbar.white {
  background-color: #fff;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s ease-in-out;
}

.navbar .logo {
  height: 80%;
}

.navbar .logo img {
  height: 100%;
  object-fit: contain;
}

.navbar.white .logo img {
  -webkit-filter: invert(95%);
          filter: invert(95%);
}

.navbar .nav {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.navbar .nav a,
.navbar .nav p.contactButton {
  margin-right: 40px;
  font-weight: 500;
  color: #fff;
  position: relative;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.navbar.white .nav a,
.navbar.white .nav p.contactButton {
  color: #333;
}

.navbar button.makePayment {
  padding: 10px 20px;
  height: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #f0ac0e;
  background-image: linear-gradient(90deg, #f0ac0e, #f56616);
  outline: none;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: inset 3px 5px 15px rgba(255, 255, 255, 0.3);
}

.navbar button.makePayment p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  -webkit-transform: translateX(7px);
          transform: translateX(7px);
  transition: -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment:hover p {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  transition: -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment i {
  color: #fff;
  font-size: 16px;
  -webkit-transform: translateX(80px);
          transform: translateX(80px);
  transition: -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment:hover i {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
  transition: -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.navbar button.makePayment:active {
  box-shadow: inset 3px 5px 15px rgba(0, 0, 0, 0.3),
    inset -5px -5px 15px rgba(255, 255, 255, 0.3);
}

.navbar .nav a::after,
.navbar .nav p.contactButton::after {
  content: "";
  position: absolute;
  bottom: 14px;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.navbar.white .nav a::after,
.navbar.white .nav p.contactButton::after {
  background-color: #333;
}

.navbar .nav a:hover::after,
.navbar .nav p.contactButton:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.modal {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 30px 40px;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 10px;
}

.modal h3 {
  color: #000;
  font-weight: 500;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.modal .inputs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
}

.modal .inputs .input {
  margin-bottom: 15px;
}

.navbar .hamburgerLines {
  width: 20px;
  height: 15px;
  display: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.navbar .hamburgerLines .line {
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.navbar .hamburger {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  display: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 100px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.navbar .hamburger a,
.navbar .hamburger p {
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 40px;
}

.navbar .hamburger a i,
.navbar .hamburger p i {
  margin-right: 15px;
}

.navbar .hamburger i.fa-times {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
  color: #fff;
}

.navbar .hamburger.active {
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 768px) {
  .navbar {
    background-color: #fff;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid #999;
  }

  .navbar img {
    -webkit-filter: invert(95%);
            filter: invert(95%);
  }

  .navbar .nav {
    display: none;
  }

  .navbar .hamburgerLines {
    display: -webkit-flex;
    display: flex;
  }

  .navbar .hamburgerLines .line {
    background-color: #333;
  }

  .navbar .hamburger button.makePayment {
    width: 80%;
    padding: 30px;
  }

  .navbar .hamburger button.makePayment p {
    font-size: 22px;
  }
}

@media (max-width: 680px) {
  .navbar {
    padding: 0 20px;
    height: 50px;
  }
  .navbar img {
    height: 70%;
  }
  .navbar .hamburger {
    padding: 100px 50px;
  }
}


.aboutTour {
  padding: 0 100px;
  overflow-y: scroll;
  margin-bottom: 40px;
}

.aboutTour::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.aboutTour .tourCard {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.aboutTour .tourCard:nth-child(even) {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.aboutTour .tourCard .left {
  width: 38%;
}

.aboutTour .tourCard .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutTour .tourCard .right {
  padding: 15px 50px;
  width: 60%;
}

.aboutTour .tourCard .right h3 {
  font-size: 32px;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  margin: 30px 0;
}

@media (max-width: 1024px) {
  .aboutTour {
    padding: 50px;
  }

  .aboutTour .tourCard .left {
    width: 45%;
  }

  .aboutTour .tourCard .right {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .aboutTour {
    margin-bottom: 0;
  }

  .aboutTour .tourCard {
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top: none;
  }

  .aboutTour .tourCard:nth-child(even) {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .aboutTour .tourCard .left,
  .aboutTour .tourCard .right {
    width: 100%;
  }

  .aboutTour .tourCard .left {
    padding: 0 50px;
  }
}

@media (max-width: 680px) {
  .aboutTour {
    padding: 40px;
  }

  .aboutTour .tourCard .left {
    padding: 0;
  }

  .aboutTour .tourCard .right {
    padding: 0 0 50px;
  }

  .aboutTour .tourCard .right p {
    text-align: justify;
  }
}

@media (max-width: 320px) {
  .aboutTour {
    padding: 25px;
  }
}

.connect {
  padding: 40px;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url("/static/media/road background.7859682e.jpg");
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.connect h2 {
  font-size: 45px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

.connect p {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
}

.connect button {
  padding: 20px 50px;
  border: 1px solid #fff;
  outline: none;
  background-color: transparent;
  font-size: 19px;
  color: #fff;
  font-weight: 500;
  margin: 30px 0;
  transition: background-color 0.3s;
  cursor: pointer;
  border-radius: 5px;
}

.connect button:hover {
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s;
}
@media (max-width: 680px) {
  .connect h2 {
    font-size: 35px;
    margin-bottom: 15px;
  }

  .connect p {
    font-size: 16px;
  }

  .connect button {
    padding: 15px 30px;
    font-size: 18px;
    margin: 20px 0 0;
  }
}

@media (max-width: 380px) {
  .connect {
    padding: 30px;
  }

  .connect h2 {
    font-size: 30px;
  }

  .connect p {
    font-size: 14px;
  }

  .connect button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 20px 0 0;
  }
}

@media (max-width: 320px) {
  .connect {
    padding: 20px;
  }
}

.hero {
  width: 100%;
  /* height: fit-content; */
  overflow: hidden;
  position: relative;
}

.hero h3 {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 80px;
  z-index: 9;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #00000080;
  padding-left: 150px;
}

.hero h3 img {
  width: 120px;
  object-fit: contain;
}

.hero img {
  width: 100%;
  object-fit: contain;
}

.hero .slick-prev {
  left: 0;
  z-index: 2;
}

.hero .slick-next {
  right: 0;
}

.hero .slick-next,
.hero .slick-prev {
  /* top: 35%; */
  background-color: #00000060 !important;
  width: 40px;
  height: 50px;
  color: #fff;
  z-index: 99;
}

.hero .slick-next:hover,
.hero .slick-prev:hover {
  background-color: #00000060;
}

.hero .slick-next::before {
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  content: "\f054";
  font-weight: 900;
  color: #fff;
}

.hero .slick-prev::before {
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  content: "\f053";
  font-weight: 900;
  color: #fff;
}

@media (max-width: 768px) {
  .hero h3 {
    padding-left: 100px;
    font-size: 50px;
  }
}

@media (max-width: 680px) {
  .hero h3 {
    padding-left: 50px;
    font-size: 30px;
  }

  .hero .slick-next,
  .hero .slick-prev {
    width: 30px;
    height: 40px;
  }

  .hero .slick-prev::before,
  .hero .slick-next::before {
    font-size: 18px;
  }
}

.map {
  padding: 50px 100px;
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin-bottom: 40px;
}

.map::before {
  content: "";
  height: 100%;
  width: 100%;
  background-image: url(/static/media/92625a55d51b7106b4f256c9c69b6e85.15a44673.jpg);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-filter: brightness(30%);
          filter: brightness(30%);
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.map .left {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 0;
}

.map .left img {
  width: 90%;
  object-fit: contain;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 15px #ffffff;
}

.map .right {
  width: 50%;
  padding: 0 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
}

.map .right h4 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 50px;
  position: relative;
  /* text-decoration: underline; */
}

.map .right h4::before {
  content: "";
  width: 81%;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.map .right ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 25px;
}

.map .right ul li {
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .map {
    padding: 50px;
  }

  .map::before {
    content: "";
    background-size: cover !important;
  }
}

@media (max-width: 768px) {
  .map {
    display: none;
  }
}

.tour {
  padding: 50px 100px;
}

.tour p {
  text-align: center;
  margin-bottom: 20px;
  color: #f3961c;
}

.tour h2 {
  font-size: 32px;
  margin-bottom: 50px;
  text-align: center;
}

.tour .cards {
  padding: 30px;
}

.tour .cards .slick-slide {
  margin-top: 30px;
  margin-bottom: 0;
  transition: margin 0.2s;
}

.tour .cards .slick-slide div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.tour .cards .slick-center {
  margin-bottom: 30px;
  margin-top: 0;
  transition: margin 0.2s;
}

.tour .cards .slick-prev {
  left: auto;
  right: 30px;
  top: auto;
  bottom: -80px;
  background-color: #77777750;
  padding: 15px;
  border-radius: 5px;
}

.tour .cards .slick-next {
  right: -10px;
  top: auto;
  bottom: -80px;
  background-color: #77777750;
  padding: 15px;
  border-radius: 5px;
}

.tour .cards .slick-prev::before {
  content: "\f060";
  color: #555;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.tour .cards .slick-next::before {
  content: "\f061";
  color: #555;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.tour .cards .slick-dots li.slick-active button::before {
  color: #f56f1a;
}

.tour .cards .slick-dots li button:hover:before {
  color: #f56f1a;
}

@media (max-width: 1024px) {
  .tour {
    padding: 50px;
    padding-bottom: 100px;
  }

  .tour .cards {
    padding: 30px 0;
  }
}

@media (max-width: 680px) {
  .tour {
    padding: 30px;
  }

  .tour p {
    margin-bottom: 10px;
  }

  .tour h2 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .tour .cards {
    padding: 0;
  }

  .tour .cards .slick-prev,
  .tour .cards .slick-next {
    bottom: -30px;
  }

  .tour .cards .slick-prev {
    right: 50px;
  }

  .tour .cards .slick-next {
    right: 10px;
  }
}

@media (max-width: 320px) {
  .tour {
    padding: 20px;
  }
}

.locationCard {
  position: relative;
  width: 350px;
  height: 480px;
}

.locationCard img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  -webkit-filter: brightness(75%);
          filter: brightness(75%);
}

.locationCard h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 45px;
  color: #fff;
  text-align: center;
}

@media (max-width: 768px) {
  .locationCard {
    width: 270px;
    height: 400px;
  }
}

.toursWeProvide {
  padding: 30px 100px 50px;
  background-color: #f8f8f8;
}

.toursWeProvide h2 {
  text-decoration: underline;
  font-size: 32px;
  margin-bottom: 40px;
  padding-left: 20px;
}

.toursWeProvide .cards {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.toursWeProvide .cards .card {
  width: 30%;
  padding: 35px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.toursWeProvide .cards .card .top {
  width: 100%;
}

.toursWeProvide .cards .card .top img {
  width: 100%;
  height: 164px;
  object-fit: cover;
  object-position: bottom;
  /* background-position: bottom; */
  border-radius: 10px;
  /* border: 1px solid #000; */
}

.toursWeProvide .cards .card:first-child .top img {
  object-position: 0 -60px;
}

.toursWeProvide .cards .card .bottom h4 {
  font-size: 25px;
  text-align: center;
  margin: 10px 0;
}

.toursWeProvide .cards .card .bottom p {
  line-height: 30px;
  text-align: justify;
}

@media (max-width: 1024px) {
  .toursWeProvide {
    padding: 50px;
  }

  .toursWeProvide .cards .card {
    width: 32%;
  }

  .toursWeProvide .cards .card .bottom p {
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .toursWeProvide .cards {
    /* flex-direction: column; */
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .toursWeProvide .cards .card {
    width: 320px;
    margin-bottom: 20px;
  }
}

@media (max-width: 680px) {
  .toursWeProvide {
    padding: 30px;
  }

  .toursWeProvide h2 {
    padding: 0;
  }

  .toursWeProvide .cards {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .toursWeProvide .cards .card:first-child .top img {
    object-position: bottom;
  }
}

@media (max-width: 320px) {
  .toursWeProvide {
    padding: 20px;
  }
}

.footer {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px 80px 0;
  color: #fff;
  margin-top: 50px;
}

.footer::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(/static/media/hand-rickshaw.bb9801f4.webp);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-filter: brightness(30%);
          filter: brightness(30%);
  z-index: -1;
}

.footer .top {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 70px;
}

.footer .top .left {
  width: 40%;
}

.footer .top .left .logoSection {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 30px;
}

.footer .top .left .logoSection img {
  height: 70px;
}

.footer .top .left .logoSection .details {
  margin-left: 20px;
}

.footer .top .left .logoSection .details h3 {
  font-size: 32px;
}

.footer .top .left .content {
  width: 92%;
}

.footer .top .left .content p {
  line-height: 22px;
  text-align: justify;
}

.footer .top .right {
  padding: 50px 30px;
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.footer .top .right .nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 40px;
}

.footer .top .right .nav-links h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

.footer .top .right .nav-links a {
  font-weight: 500;
  color: #fff;
  margin-bottom: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.footer .top .right .nav-links.social a {
  margin-bottom: 10px;
}

.footer .top .right .nav-links.contact a {
  margin-bottom: 13px;
}

.footer .top .right .nav-links.contact a i {
  font-size: 20px;
}

.footer .top .right .nav-links a i {
  font-size: 25px;
  margin-right: 10px;
}

.footer .bottom {
  padding: 30px 50px;
  border-top: 1px solid #fff;
}

.footer .bottom p {
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
}

.footer .bottom p a {
  color: #40ae77;
}

@media (max-width: 1100px) {
  .footer::before {
    background-size: cover;
  }

  .footer .top {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0;
  }

  .footer .top .left {
    width: 100%;
  }

  .footer .top .left .content {
    width: 100%;
  }

  .footer .top .right {
    width: auto;
    padding: 50px 0;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .footer .top .right {
    width: 100%;
  }
}

@media (max-width: 680px) {
  .footer {
    padding: 35px 40px 0;
  }

  .footer .top .left .logoSection {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .footer .top .left .logoSection img {
    height: 55px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  .footer .top .left .logoSection .details {
    margin: 0;
  }

  .footer .top .left .logoSection .details h3 {
    font-size: 30px;
  }

  .footer .top .left .logoSection .details p {
    font-size: 18px;
  }

  .footer .top .right {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 0px;
  }

  .footer .top .right .nav-links {
    margin-bottom: 20px;
  }

  .footer .top .right .nav-links h4 {
    font-size: 23px;
    margin-bottom: 10px;
  }

  .footer .bottom {
    padding: 20px 0;
  }
}

@media (max-width: 380px) {
  .footer {
    padding: 35px 35px 0;
  }

  .footer .top .left .logoSection .details h3 {
    font-size: 28px;
  }
}

@media (max-width: 320px) {
  .footer {
    padding: 30px 20px 0;
  }

  .footer .top .left .logoSection .details h3 {
    font-size: 26px;
  }
}

.services {
  padding: 0 5.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #eeeeee;
  position: relative;
  padding-top: 2rem;
}

.services h1 {
  font-size: 40px;
  color: #fff;
  margin: 40px 0;
  text-shadow: 3px 3px 8px #33333395;
}

.services .services-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 2rem;
  margin-bottom: 60px;
}

.services .services-body .services-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.services .services-body .services-content .top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 5px 5px 15px rgb(126, 125, 125);
  margin-top: 30px;
}

.services .services-body .services-content:nth-child(even) .top {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.services .services-body .services-content .top img {
  width: 500px;
  height: 295px;
  object-fit: cover;
  background-color: #fff;
  border-radius: 15px;
  padding: 0.5rem 1.5rem;
}

.services .services-body .services-content .top .content {
  width: 55%;
  padding: 4rem;
  background-color: #fff;
  border-radius: 15px;
  margin: 0 auto;
}

.services .services-body .services-content .top .content h2 {
  margin-bottom: 20px;
}

.services .services-body .services-content .top .content p {
  line-height: 26px;
  text-align: justify;
}

@media (max-width: 1200px) {
  .services .services-body .services-content .top img {
    height: auto;
    width: 50%;
    /* height: 300px; */
  }
}

@media (max-width: 1024px) {
  .services {
    padding: 0 2rem;
  }
  .services .services-body .services-content .top .content {
    padding: 2rem;
    width: 45%;
  }
  .services .services-body .services-content .top img {
    height: auto;
    width: 50%;
    /* height: 300px; */
  }
}

@media (max-width: 768px) {
  .services .services-body .services-content .top {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 3rem 2rem;
  }

  .services .services-body .services-content:nth-child(even) .top {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 3rem 2rem;
  }

  .services .services-body .services-content .top img {
    height: auto;
    width: 100%;
    /* height: 300px; */
  }

  .services .services-body .services-content .top .content {
    width: 90%;
  }
}

@media (max-width: 680px) {
  .services {
    padding: 0;
  }

  .services h1 {
    margin: 30px 20px 25px;
    font-size: 34px;
  }

  .services .services-body {
    padding: 0 1rem;
  }

  .services .services-body .services-content {
    margin-top: 1rem;
  }

  .services .services-body .services-content .top {
    padding: 2rem;
    margin: 0;
  }

  .services .services-body .services-content .top .content {
    width: 95%;
    padding: 0;
    margin-top: 50px;
  }
  .services .services-body .services-content .bottom .arrow {
    height: 65px;
    margin: 20px;
    -webkit-filter: drop-shadow(3px 3px rgb(37, 138, 106));
            filter: drop-shadow(3px 3px rgb(37, 138, 106));
  }
}

@media (max-width: 468px) {
  .services h1 {
    margin: 30px 20px 25px;
    font-size: 30px;
  }
}

.cars-cont {
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */
  background-color: rgb(2, 23, 27);
  color: #fff;
}

.cars-cont .image {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 2.5rem;
}

.cars-cont .image img {
  width: 70%;
  margin: 2rem;
  border-radius: 3px;
  box-shadow: 5px 5px 15px rgb(128, 128, 128);
}

.cars-cont .cont {
  width: 100%;
  overflow: hidden;
  object-fit: contain;
}

.cars-cont .sec-cont {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* background-color: aqua; */
}

.cars-cont .sec-cont .car-des {
  padding: 0 6rem;
  width: 100%;
  margin-bottom: 2rem;
}

.cars-cont .sec-cont .car-des h4 {
  margin: 2rem 0;
  font-size: 30px;
  margin-top: 4rem;
}

.cars-cont .sec-cont .car-des .other {
  margin: 0 0 1rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  box-shadow: 0 0 15px rgb(128, 128, 128);
  border-radius: 25px;
  background-color: #fff;
  color: #000;
}

.cars-cont .sec-cont .car-des .other .section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
}

.cars-cont .sec-cont .car-des .other .section img {
  width: 30%;
  border-radius: 7px;
}

.cars-cont .sec-cont .car-des .other .section h4 {
  font-size: 25px;
}

@media (max-width: 780px) {
  .cars-cont .sec-cont .car-des {
    padding: 0 4rem;
  }
}

@media (max-width: 680px) {
  .cars-cont .sec-cont .car-des h4 {
    font-size: 25px;
  }

  .cars-cont .sec-cont .car-des {
    padding: 0 3rem;
  }
}

@media (max-width: 580px) {
  .cars-cont .sec-cont .car-des h4 {
    font-size: 20px;
  }

  .cars-cont .sec-cont .car-des {
    padding: 0 2rem;
  }
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 9999;
}

.contact-back {
  width: 100%;
  height: 100%;
  /* filter: blur(95%); */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.1);
}

.contact-back .contact {
  background-color: rgba(255, 255, 255, 0.45);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  position: relative;
  border: 1px solid #fff;
}

.contact-back .contact i {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #000;
  cursor: pointer;
}

.contact-back .contact .contact-head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.contact-back .contact .contact-head .left {
  height: 60px;
  margin-right: 4rem;
}

.contact-back .contact .contact-head .left img {
  height: 100%;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.contact-back .contact .contact-head .right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.contact-back .contact .contact-head .right p,
.contact-back .contact .contact-head .right a {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

.contact-back .contact .contact-head .right p:first-child {
  margin-bottom: 5px;
}

.contact-back .contact .contact-head .right p:nth-child(3) {
  margin: 2px 0;
}

.contact-back .contact .contact-body {
  padding: 2rem 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 10px;
  border-radius: 10px;
}

.contact-back .contact .contact-body .form-group {
  height: 40px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.contact-back .contact .contact-body .form-group .input {
  background-color: #fff;
  /* overflow: hidden; */
}

/* .contact-back .contact .contact-body .form-group input:focus {
  border: 1px solid orangered;
  transition: border 0.2s;
}

.contact-back .contact .contact-body .form-group input:focus ~ label {
  top: -20%;
  left: 0.7rem;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.3s;
  /* color: #fff; */
/* background-color: rgba(255, 255, 255, 0s.2); 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox 
input[type="number"] {
  -moz-appearance: textfield;
} */

/* .contact-back .contact .contact-body .form-group label {
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  left: 16px;
  transition: all 0.3s;
  background-color: transparent;
}

.contact-back .contact .contact-body .form-group label.active {
  top: -20%;
  left: 0.7rem;
  font-size: 12px;
  font-weight: 500;
} */

.contact-back .contact .contact-body .twoFields {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 5px;
}
.contact-back .contact .contact-body .twoFields .form-group:first-child {
  margin-right: 10px;
}

select {
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  border-radius: 10px;
  padding-left: 1rem;
  background-color: #fff;
}

select:first-child {
  margin-right: 10px;
}

.contact-back .contact .contact-footer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
}

.contact-back .contact .contact-footer button {
  padding: 0.7rem 2rem;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-radius: 10px;
  outline: none;
  background-color: #40ae77;
  border: 1px solid #40ae77;
  box-shadow: inset -2px -2px 7px rgba(0, 0, 0, 0.5),
    inset 2px 2px 7px rgba(255, 255, 255, 0.6);
  color: #fff;
  cursor: pointer;
}

.contact-back .contact .contact-footer button:active {
  box-shadow: inset 3px 3px 7px rgba(0, 0, 0, 0.5),
    inset -3px -3px 7px rgba(255, 255, 255, 0.6);
  background-color: #40ae77;
}

@media (max-width: 680px) {
  .contact-back .contact .contact-head .left {
    margin-bottom: 10px;
  }
  .contact-back .contact .contact-head {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .contact-back .contact .contact-body {
    padding: 0;
  }
}

.galleryIn {
  margin-top: 60px;
  position: relative;
}

.galleryIn .heading {
  padding: 50px 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.galleryIn .heading h4 {
  font-size: 36px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.galleryIn .heading i {
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  font-size: 36px;
  transition: color 0.3s;
}

.galleryIn .heading i:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.galleryIn .heading i.grey-star {
  color: #77777750;
}

.galleryIn .heading i.golden-star {
  color: #ffd700;
}

.galleryIn .body {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0 120px;
  /* justify-content: space-between; */
}

.galleryIn .body img {
  /* width: 24%; */
  height: 300px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  object-fit: contain;
  margin-right: 10px;
}

.galleryIn .slider {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}

.galleryIn .slider::before {
  content: "";
  background-color: #00000095;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.galleryIn .slider i {
  position: absolute;
  top: 20px;
  right: 50px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  z-index: 999;
}

.galleryIn .slider .sliderImage {
  width: 100%;
  height: 100vh;
  padding: 80px 0 100px;
  outline: none;
}

.galleryIn .slider .sliderImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.galleryIn .slider .slick-prev {
  left: 0;
  background-color: #00000050;
  padding: 30px 20px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 999;
}

.galleryIn .slider .slick-prev::before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 30px;
}

.galleryIn .slider .slick-next {
  right: 0;
  background-color: #00000050;
  padding: 30px 20px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.galleryIn .slider .slick-next::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 30px;
}

.galleryIn .slider .slick-dots {
  bottom: 0;
}

.galleryIn .slider .slick-dots li {
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.galleryIn .slider .slick-dots li.slick-active {
  border: 3px solid #fff;
  border-radius: 5px;
  overflow: hidden;
}

.galleryIn .slider .slick-dots li .imageDots {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .galleryIn .heading {
    padding: 50px 55px;
  }

  .galleryIn .body {
    padding: 0 55px;
  }

  .galleryIn .body img {
    height: 220px;
  }
  .galleryIn .slider i {
    right: 25px;
  }
}

@media (max-width: 680px) {
  .galleryIn .heading {
    padding: 50px 20px;
  }

  .galleryIn .heading h4 {
    font-size: 32px;
  }

  .galleryIn .heading i {
    font-size: 26px;
  }

  .galleryIn .body {
    padding: 0 20px;
  }

  .galleryIn .body img {
    height: 120px;
    margin-bottom: 10px;
  }

  .galleryIn .slider i {
    right: 35px;
  }

  .galleryIn .slider .slick-next {
    right: 17px;
  }
}

@media (max-width: 368px) {
  .galleryIn .heading {
    padding: 30px 20px;
  }

  .galleryIn .heading h4 {
    font-size: 25px;
  }

  .galleryIn .slider i {
    right: 28px;
  }

  .galleryIn .body img {
    height: 100px;
  }
}

.gallery {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 60px;
}

.gallery .heading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 50px 120px;
}

.gallery .heading h4 {
  font-size: 30px;
}

.gallery .heading a {
  padding: 10px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.gallery .heading a i {
  background-image: linear-gradient(
    -120deg,
    #4285f4,
    #34a853,
    #fbbc05,
    #ea4335
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 5px;
}

.gallery .heading a:focus {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.gallery .respo {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

/* .gallery .respo a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.gallery .gallery-cont {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* justify-content: center; */
  -webkit-align-items: center;
          align-items: center;
  /* margin: 2rem; */
  padding: 0 40px;
}

.gallery .gallery-cont .gallery-show {
  height: 70%;
  width: 400px;
  position: relative;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 2rem;
  /* background-color: #000; */
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.gallery .gallery-cont .gallery-show img {
  /* height: 300px; */
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.gallery .gallery-cont .gallery-show .content {
  /* position: absolute;
  bottom: 25%; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 30%;
  width: 100%;
  background-color: #fff;
  margin-top: 54%;
  box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.15);
}

.gallery .gallery-cont .gallery-show .content p {
  font-size: 30px;
  /* padding: 1rem 1rem 0 0; */
  padding: 1rem 0;
  font-weight: 500;
  color: rgb(37, 36, 36);
  width: 90%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* @media only screen and (max-width: 1440px) {
  .gallery .gallery-cont {
    padding-left: 4rem;
  }
} */

@media (max-width: 1350px) {
  .gallery .gallery-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }
}

@media (max-width: 680px) {
  .gallery .heading {
    padding: 50px 70px;
  }

  .gallery .gallery-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }
}

@media (max-width: 580px) {
  .gallery .heading {
    padding: 50px 40px;
  }

  .gallery .heading h4 {
    font-size: 24px;
  }

  .gallery .gallery-cont .gallery-show .content p {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .gallery .heading {
    padding: 50px 20px;
  }
}

@media (max-width: 400px) {
  .gallery .heading h4 {
    font-size: 20px;
  }

  .gallery .gallery-cont .gallery-show .content p {
    font-size: 22px;
  }
}

.gallery-out {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 60px;
}

.gallery-out .heading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 50px 120px;
}

.gallery-out .heading h4 {
  font-size: 30px;
}

.gallery-out .heading a {
  padding: 10px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.gallery-out .heading a i {
  background-image: linear-gradient(
    -120deg,
    #4285f4,
    #34a853,
    #fbbc05,
    #ea4335
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 5px;
}

.gallery-out .heading a:focus {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.gallery-out .respo {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

/* .gallery-out .respo a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.gallery-out .gallery-cont {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  /* margin: 2rem; */
  padding: 0 40px;
}

.gallery-out .gallery-cont .gallery-show {
  height: 70%;
  width: 400px;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  /* background-color: #000; */
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.gallery-out .gallery-cont .gallery-show img {
  /* height: 300px; */
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.gallery-out .gallery-cont .gallery-show .content {
  /* position: absolute;
  bottom: 25%; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 30%;
  width: 100%;
  background-color: #fff;
  margin-top: 54%;
  box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.15);
}

.gallery-out .gallery-cont .gallery-show .content p {
  font-size: 30px;
  /* padding: 1rem 1rem 0 0; */
  padding: 1rem 0;
  font-weight: 500;
  color: rgb(37, 36, 36);
  width: 90%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* @media only screen and (max-width: 1440px) {
  .gallery-out .gallery-cont {
    padding-left: 4rem;
  }
} */

@media (max-width: 1350px) {
  .gallery-out .gallery-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }
}

@media (max-width: 680px) {
  .gallery-out .heading {
    padding: 50px 70px;
  }

  .gallery-out .gallery-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }
}

@media (max-width: 580px) {
  .gallery-out .heading {
    padding: 50px 40px;
  }

  .gallery-out .heading h4 {
    font-size: 24px;
  }

  .gallery-out .gallery-cont .gallery-show .content p {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .gallery-out .heading {
    padding: 50px 20px;
  }
}

@media (max-width: 400px) {
  .gallery-out .heading h4 {
    font-size: 20px;
  }

  .gallery-out .gallery-cont .gallery-show .content p {
    font-size: 22px;
  }
}

