.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px 80px 0;
  color: #fff;
  margin-top: 50px;
}

.footer::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/hand-rickshaw.webp");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  filter: brightness(30%);
  z-index: -1;
}

.footer .top {
  width: 100%;
  display: flex;
  margin-bottom: 70px;
}

.footer .top .left {
  width: 40%;
}

.footer .top .left .logoSection {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.footer .top .left .logoSection img {
  height: 70px;
}

.footer .top .left .logoSection .details {
  margin-left: 20px;
}

.footer .top .left .logoSection .details h3 {
  font-size: 32px;
}

.footer .top .left .content {
  width: 92%;
}

.footer .top .left .content p {
  line-height: 22px;
  text-align: justify;
}

.footer .top .right {
  padding: 50px 30px;
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.footer .top .right .nav-links {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.footer .top .right .nav-links h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

.footer .top .right .nav-links a {
  font-weight: 500;
  color: #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.footer .top .right .nav-links.social a {
  margin-bottom: 10px;
}

.footer .top .right .nav-links.contact a {
  margin-bottom: 13px;
}

.footer .top .right .nav-links.contact a i {
  font-size: 20px;
}

.footer .top .right .nav-links a i {
  font-size: 25px;
  margin-right: 10px;
}

.footer .bottom {
  padding: 30px 50px;
  border-top: 1px solid #fff;
}

.footer .bottom p {
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
}

.footer .bottom p a {
  color: #40ae77;
}

@media (max-width: 1100px) {
  .footer::before {
    background-size: cover;
  }

  .footer .top {
    flex-direction: column;
    margin-bottom: 0;
  }

  .footer .top .left {
    width: 100%;
  }

  .footer .top .left .content {
    width: 100%;
  }

  .footer .top .right {
    width: auto;
    padding: 50px 0;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .footer .top .right {
    width: 100%;
  }
}

@media (max-width: 680px) {
  .footer {
    padding: 35px 40px 0;
  }

  .footer .top .left .logoSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer .top .left .logoSection img {
    height: 55px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  .footer .top .left .logoSection .details {
    margin: 0;
  }

  .footer .top .left .logoSection .details h3 {
    font-size: 30px;
  }

  .footer .top .left .logoSection .details p {
    font-size: 18px;
  }

  .footer .top .right {
    flex-direction: column;
    padding-bottom: 0px;
  }

  .footer .top .right .nav-links {
    margin-bottom: 20px;
  }

  .footer .top .right .nav-links h4 {
    font-size: 23px;
    margin-bottom: 10px;
  }

  .footer .bottom {
    padding: 20px 0;
  }
}

@media (max-width: 380px) {
  .footer {
    padding: 35px 35px 0;
  }

  .footer .top .left .logoSection .details h3 {
    font-size: 28px;
  }
}

@media (max-width: 320px) {
  .footer {
    padding: 30px 20px 0;
  }

  .footer .top .left .logoSection .details h3 {
    font-size: 26px;
  }
}
