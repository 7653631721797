.services {
  padding: 0 5.5rem;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  position: relative;
  padding-top: 2rem;
}

.services h1 {
  font-size: 40px;
  color: #fff;
  margin: 40px 0;
  text-shadow: 3px 3px 8px #33333395;
}

.services .services-body {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  margin-bottom: 60px;
}

.services .services-body .services-content {
  display: flex;
  flex-direction: column;
}

.services .services-body .services-content .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 5px 5px 15px rgb(126, 125, 125);
  margin-top: 30px;
}

.services .services-body .services-content:nth-child(even) .top {
  flex-direction: row-reverse;
}

.services .services-body .services-content .top img {
  width: 500px;
  height: 295px;
  object-fit: cover;
  background-color: #fff;
  border-radius: 15px;
  padding: 0.5rem 1.5rem;
}

.services .services-body .services-content .top .content {
  width: 55%;
  padding: 4rem;
  background-color: #fff;
  border-radius: 15px;
  margin: 0 auto;
}

.services .services-body .services-content .top .content h2 {
  margin-bottom: 20px;
}

.services .services-body .services-content .top .content p {
  line-height: 26px;
  text-align: justify;
}

@media (max-width: 1200px) {
  .services .services-body .services-content .top img {
    height: auto;
    width: 50%;
    /* height: 300px; */
  }
}

@media (max-width: 1024px) {
  .services {
    padding: 0 2rem;
  }
  .services .services-body .services-content .top .content {
    padding: 2rem;
    width: 45%;
  }
  .services .services-body .services-content .top img {
    height: auto;
    width: 50%;
    /* height: 300px; */
  }
}

@media (max-width: 768px) {
  .services .services-body .services-content .top {
    flex-direction: column;
    padding: 3rem 2rem;
  }

  .services .services-body .services-content:nth-child(even) .top {
    flex-direction: column;
    padding: 3rem 2rem;
  }

  .services .services-body .services-content .top img {
    height: auto;
    width: 100%;
    /* height: 300px; */
  }

  .services .services-body .services-content .top .content {
    width: 90%;
  }
}

@media (max-width: 680px) {
  .services {
    padding: 0;
  }

  .services h1 {
    margin: 30px 20px 25px;
    font-size: 34px;
  }

  .services .services-body {
    padding: 0 1rem;
  }

  .services .services-body .services-content {
    margin-top: 1rem;
  }

  .services .services-body .services-content .top {
    padding: 2rem;
    margin: 0;
  }

  .services .services-body .services-content .top .content {
    width: 95%;
    padding: 0;
    margin-top: 50px;
  }
  .services .services-body .services-content .bottom .arrow {
    height: 65px;
    margin: 20px;
    filter: drop-shadow(3px 3px rgb(37, 138, 106));
  }
}

@media (max-width: 468px) {
  .services h1 {
    margin: 30px 20px 25px;
    font-size: 30px;
  }
}
