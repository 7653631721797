.cars-cont {
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */
  background-color: rgb(2, 23, 27);
  color: #fff;
}

.cars-cont .image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
}

.cars-cont .image img {
  width: 70%;
  margin: 2rem;
  border-radius: 3px;
  box-shadow: 5px 5px 15px rgb(128, 128, 128);
}

.cars-cont .cont {
  width: 100%;
  overflow: hidden;
  object-fit: contain;
}

.cars-cont .sec-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
}

.cars-cont .sec-cont .car-des {
  padding: 0 6rem;
  width: 100%;
  margin-bottom: 2rem;
}

.cars-cont .sec-cont .car-des h4 {
  margin: 2rem 0;
  font-size: 30px;
  margin-top: 4rem;
}

.cars-cont .sec-cont .car-des .other {
  margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 15px rgb(128, 128, 128);
  border-radius: 25px;
  background-color: #fff;
  color: #000;
}

.cars-cont .sec-cont .car-des .other .section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
}

.cars-cont .sec-cont .car-des .other .section img {
  width: 30%;
  border-radius: 7px;
}

.cars-cont .sec-cont .car-des .other .section h4 {
  font-size: 25px;
}

@media (max-width: 780px) {
  .cars-cont .sec-cont .car-des {
    padding: 0 4rem;
  }
}

@media (max-width: 680px) {
  .cars-cont .sec-cont .car-des h4 {
    font-size: 25px;
  }

  .cars-cont .sec-cont .car-des {
    padding: 0 3rem;
  }
}

@media (max-width: 580px) {
  .cars-cont .sec-cont .car-des h4 {
    font-size: 20px;
  }

  .cars-cont .sec-cont .car-des {
    padding: 0 2rem;
  }
}
