.gallery {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.gallery .heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 50px 120px;
}

.gallery .heading h4 {
  font-size: 30px;
}

.gallery .heading a {
  padding: 10px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.gallery .heading a i {
  background-image: linear-gradient(
    -120deg,
    #4285f4,
    #34a853,
    #fbbc05,
    #ea4335
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 5px;
}

.gallery .heading a:focus {
  transform: scale(0.97);
}

.gallery .respo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .gallery .respo a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.gallery .gallery-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  /* margin: 2rem; */
  padding: 0 40px;
}

.gallery .gallery-cont .gallery-show {
  height: 70%;
  width: 400px;
  position: relative;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 2rem;
  /* background-color: #000; */
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.gallery .gallery-cont .gallery-show img {
  /* height: 300px; */
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.gallery .gallery-cont .gallery-show .content {
  /* position: absolute;
  bottom: 25%; */
  display: flex;
  justify-content: center;
  height: 30%;
  width: 100%;
  background-color: #fff;
  margin-top: 54%;
  box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.15);
}

.gallery .gallery-cont .gallery-show .content p {
  font-size: 30px;
  /* padding: 1rem 1rem 0 0; */
  padding: 1rem 0;
  font-weight: 500;
  color: rgb(37, 36, 36);
  width: 90%;
  user-select: none;
}

/* @media only screen and (max-width: 1440px) {
  .gallery .gallery-cont {
    padding-left: 4rem;
  }
} */

@media (max-width: 1350px) {
  .gallery .gallery-cont {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 680px) {
  .gallery .heading {
    padding: 50px 70px;
  }

  .gallery .gallery-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 580px) {
  .gallery .heading {
    padding: 50px 40px;
  }

  .gallery .heading h4 {
    font-size: 24px;
  }

  .gallery .gallery-cont .gallery-show .content p {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .gallery .heading {
    padding: 50px 20px;
  }
}

@media (max-width: 400px) {
  .gallery .heading h4 {
    font-size: 20px;
  }

  .gallery .gallery-cont .gallery-show .content p {
    font-size: 22px;
  }
}
